<template>
	<div class="SuggestReply">
		<w-navTab titleText="回复内容"></w-navTab>
		<div class="SuggestReply-container">
			<div class="middle-text">
				<textarea placeholder="请输入内容" v-model="content"></textarea>
				<!-- <div class="middle-footer-left">
					<div>({{ content.length }}/200)</div>
				</div> -->
			</div>
			<div class="footer">
				<div class="footer-btn" @click="onSubmit">提交</div>
			</div>
		</div>
	</div>
</template>

<script>
	import CompanyApi from '@/api/company';
	import Vant from '@/vendor/vant.js';
	export default {
		inject: ['themeColor', 'className', 'districtType'],
		name: 'SuggestReply',
		data() {
			return {
				content: '',
				id: ''
			}
		},
		created() {
			this.id = this.$route.params.id
		},
		methods: {
			onSubmit() {
				let params = {
					id: this.id,
					reply_content: this.content
				}
				CompanyApi.suggestReply(params)
					.then(result => {
						Vant.Toast.success('提交成功');
						this.content = ''
						setTimeout(() => {
							this.$router.go(-1)
						}, 1000)
					})
					.catch(error => {
						Vant.Toast.fail(error.msg);
					})
			}
		}
	};
</script>

<style scoped lang="less">
	.SuggestReply.city {
		.footer {
			.footer-btn {
				background: #3377ff;
			}
		}
	}

	.SuggestReply.district {
		.footer {
			.footer-btn {
				background: #FF6969;
			}
		}
	}



	.SuggestReply {
		position: relative;
		box-sizing: border-box;
		background-color: #fff;

		.SuggestReply-container {
			padding: 16px;
			box-sizing: border-box;

			.middle-text {
				width: 100%;
				height: 100%;
				padding: 16px 16px 20px;
				background-color: #FAFAFA;
				box-sizing: border-box;
				border-radius: 6px;
				position: relative;
				
				textarea {
					width: 100%;
					height: 324px;
					background-color: #FAFAFA;
					border: 0;
					padding: 0;
					font-size: 16px;
				}

				.middle-footer-left {
					position: absolute;
					right: 20px;
					bottom: 10px;
					font-size: 14px;
					line-height: 26px;
					color: #666;
				}
			}

			.footer {
				border-radius: 6px;
				width: 100%;
				padding: 0 10px;
				position: absolute;
				left: 0;
				bottom: 20px;
				box-sizing: border-box;

				.footer-btn {
					width: 100%;
					padding: 8px 16px;
					font-size: 16px;
					line-height: 26px;
					color: #fff;
					box-sizing: border-box;
					border-radius: 100px;
					text-align: center;
				}

			}
		}
	}
</style>